import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

const getTeamImages = graphql`
  {
    image1: file(relativePath: { eq: "blog-one.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "blog-two.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "blog-three.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "blog-four.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const pageQuery = graphql`
  query AnimalIndexQuery {
    allContentfulAnimalPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        qty
        publishDate(formatString: "MMMM Do, YYYY")
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
  }
`

const Blog = () => {
  const data = useStaticQuery(getTeamImages)
  return (
    <section id="blog" className="our-blog ptb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <div className="section-title">
              <h2>Les animaux</h2>
              <p>
                TALI N'NA Zoo regroupe les animaux d'espèces variées retrouvées
                dans les zones tropicales.
              </p>
              <span className="section-title-bg">Blog</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="blog-item">
              <a href="/blog-details" className="blog-img">
                <img
                  src={data.image1.childImageSharp.fluid.src}
                  alt="blog-one"
                />
              </a>
              <div className="blog-info">
                <div className="date-box">12</div>
                <div className="title-meta">
                  <h2>
                    <a href="/blog-details">
                      14 ridiculously cool websites you never know.
                    </a>
                  </h2>
                </div>
              </div>
              <div className="post-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="blog-item">
              <a href="/blog-details" className="blog-img">
                <img
                  src={data.image2.childImageSharp.fluid.src}
                  alt="blog-one"
                />
              </a>
              <div className="blog-info">
                <div className="date-box">12</div>
                <div className="title-meta">
                  <h2>
                    <a href="/blog-details">
                      14 ridiculously cool websites you never know.
                    </a>
                  </h2>
                </div>
              </div>
              <div className="post-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="blog-item">
              <a href="/blog-details" className="blog-img">
                <img
                  src={data.image3.childImageSharp.fluid.src}
                  alt="blog-one"
                />
              </a>
              <div className="blog-info">
                <div className="date-box">12</div>
                <div className="title-meta">
                  <h2>
                    <a href="/blog-details">
                      14 ridiculously cool websites you never know.
                    </a>
                  </h2>
                </div>
              </div>
              <div className="post-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="blog-item">
              <a href="/blog-details" className="blog-img">
                <img
                  src={data.image4.childImageSharp.fluid.src}
                  alt="blog-one"
                />
              </a>
              <div className="blog-info">
                <div className="date-box">12</div>
                <div className="title-meta">
                  <h2>
                    <a href="/blog-details">
                      14 ridiculously cool websites you never know.
                    </a>
                  </h2>
                </div>
              </div>
              <div className="post-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 all-post">
            <div className="center-wrap">
              <Link onClick={() => alert('Allo')} className="btn-a">
                <div className="button">
                  Voir plus <i className="fa fa-arrow-right"></i>
                  <div className="mask"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
