import React from 'react'
import PropTypes from 'prop-types'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation'
import axios from 'axios'
import CustomMap from './CustomMap'
import ContactForm from '../App/ContactForm'

const Contact = (props) => {
  const GATEWAY_URL =
    'https://mfgq7cu8q3.execute-api.us-east-1.amazonaws.com/prod/contact-us'


  const handleForm = async (e, formData) => {
    e.preventDefault()
    //alert(JSON.stringify(formData))
    axios
      .post(GATEWAY_URL, formData)
      .then(function (response) {
        console.log(response)
        alert(JSON.stringify('RES >> '+response))
      })
      .catch(function (error) {
        console.log(error)
      })
    // try {
    //   const res = await fetch(GATEWAY_URL, {
    //     method: 'POST',
    //     mode: 'CORS',
    //     cache: 'no-cache',
    //     body: JSON.stringify(formData),
    //     headers: {
    //       'Content-type': 'application/json; charset=UTF-8',
    //     },
    //   })
    //   alert(JSON.stringify(res))
    //   if (!res.ok) {
    //     const message = `An error has occured: ${res.status} - ${res.statusText}`
    //     throw new Error(message)
    //   }
    //   const data = await res.json()
    //   const result = {
    //     status: res.status + '-' + res.statusText,
    //     headers: {
    //       'Content-Type': res.headers.get('Content-Type'),
    //       'Content-Length': res.headers.get('Content-Length'),
    //     },
    //     data: data,
    //   }
    //   alert(JSON.stringify(data))
    // } catch (error) {
    //   // handle server errors
    // }
  }
  
  
  return (
    <section id="contact" className="contact-area ptb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <div className="section-title">
              <h2>{props.sectionTitle}</h2>
              <p>{props.sectionDescription}</p>
              <span className="section-title-bg">{props.SectionbgTitle}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="addess">
              <div className="row">
                <div className="col-lg-1 col-md-1">
                  <i className="fa fa-map-marker"></i>
                </div>
                <div className="col-lg-11 col-md-11">
                  <h4>{props.AddTitle}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <p>{props.Address}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="email">
              <div className="row">
                <div className="col-lg-1 col-md-1">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="col-lg-11 col-md-11">
                  <h4>{props.EmailTitle}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <p>{props.Email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="phone">
              <div className="row">
                <div className="col-lg-1 col-md-1">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="col-lg-11 col-md-11">
                  <h4>{props.PhoneTitle}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <p>{props.Phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="contact-form">
                <ContactForm />
              {/* <ValidationForm id="contactForm" onSubmit={handleForm}>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="name"
                        id="name"
                        required
                        successMessage=""
                        errorMessage="Saisir nom et prénoms"
                        className="form-control"
                        placeholder="Nom et Prénoms"
                        autoComplete="off"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="email"
                        id="email"
                        type="email"
                        required
                        successMessage=""
                        errorMessage="Saisir une adresse mail"
                        className="form-control"
                        placeholder="Email"
                        autoComplete="off"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="phone"
                        id="phone"
                        type="text"
                        successMessage=""
                        errorMessage="Saisir le numéro de Téléphone"
                        className="form-control"
                        placeholder="Téléphone"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="subject"
                        id="subject"
                        type="text"
                        successMessage=""
                        errorMessage="Saisir l'objet du message"
                        className="form-control"
                        placeholder="Objet"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <TextInput
                        name="message"
                        id="description"
                        multiline
                        placeholder="Votre message"
                        className="form-control"
                        required
                        successMessage=""
                        errorMessage="Saisir le message ici"
                        rows="5"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>

                <div className="center-wrap text-center">
                  <div className="button">
                    <button type="submit">
                      Envoyer <i className="fa fa-long-arrow-right"></i>{' '}
                    </button>
                  </div>
                </div>
                <div className="clearfix" />
              </ValidationForm> */}
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="address-area">
              <CustomMap />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

//Props Types
Contact.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  AddTitle: PropTypes.string,
  Address: PropTypes.string,
  EmailTitle: PropTypes.string,
  Email: PropTypes.string,
  PhoneTitle: PropTypes.string,
  Phone: PropTypes.string,
}

//Default Props
Contact.defaultProps = {
  SectionbgTitle: 'Contact',
  sectionTitle: 'Nous Contacter',
  sectionDescription:
    "Voudriez-vous demander quelques informations concernant le Mini Zoo Parc TALI N'NA? Veuillez remplir les champs ci-dessous, nous nous ferons plaisir de vous y répondre dans les brefs délais.",
  AddTitle: 'Adresse',
  Address: 'Evou-Kodégbé, Amou - Togo.',
  EmailTitle: 'Email',
  Email: 'contact@talinnaminizootogo.com',
  PhoneTitle: 'Téléphone',
  Phone: '+228 98 47 87 87 / 91 64 33 33',
}

export default Contact
