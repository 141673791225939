import React from 'react'

function CustomMap() {
  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width="600"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=G2HV+Q65,%20Unnamed%20Road,%20Temedja&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        />
      </div>
    </div>
  )
}
export default CustomMap