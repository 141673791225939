import React from 'react'
import { Link } from 'gatsby'
import ModalVideo from 'react-modal-video'

const VideoArea = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  return (
    <section className="video-area video-bg">
      <div className="diplay-table">
        <div className="display-table-cell">
          <div className="video-inner-content">
            <div className="button__holder">
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="s0nStqnhZ38"
                onClose={() => setIsOpen(false)}
              />
              <Link
                to="#"
                className="plus popup-youtube"
                onClick={(e) => {
                  e.preventDefault()
                  openModal()
                }}
              ></Link>
            </div>
            <h2>Video d'Introduction</h2>
            <p>Le MINI ZOO PARC TALI N'NA en vidéo. Cliquer pour visualiser.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoArea
