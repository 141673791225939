import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { toast, Rotate } from 'react-toastify'

const GATEWAY_URL = process.env.GATSBY_FWD_API_URL

const ContactForm = () => {
  const toastId = React.useRef(null)

  const notify = () => {
    toastId.current = toast('Envoi de la demande en cours ...', {
      autoClose: false,
      closeButton: false, // Remove the closeButton
      className: 'black-background',
    })
  }

  const update = () => {
    toast.update(toastId.current, {
      render: 'Demande envoyée avec succès',
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: null, // The closeButton defined on ToastContainer will be used
      className: 'rotateY animated',
    })
  }

  const updateError = () => {
    toast.update(toastId.current, {
      render: 'Erreur lors de l envoi de la demande! Ressayer plus tard SVP!',
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: null, // The closeButton defined on ToastContainer will be used
      className: 'rotateY animated',
    })
  }

  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Saisir les nom et prénoms'),
          email: Yup.string()
            .email('Email non valide!')
            .required('Saisir une adresse email'),
          phone: Yup.string().required('Saisir un numero de telephone'),
          subject: Yup.string().required(`Saisir l'objet du message`),
          message: Yup.string().required('Saisir le message'),
        })}
        onSubmit={async (values, { resetForm }) => {
          notify()
          axios
            .post(`${GATEWAY_URL}/contact-us`, values)
            .then(function (response) {
              console.log(response)
              update()
              resetForm()
            })
            .catch(function (error) {
              console.log(error)
              updateError()
            })
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <Field
                    name="name"
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Nom & prénoms "
                    disabled={isSubmitting}
                  />
                  {errors.name && touched.name ? (
                    <div className="errors">{errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <Field
                    name="email"
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Email "
                    disabled={isSubmitting}
                  />
                  {errors.email && touched.email ? (
                    <div className="errors">{errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <Field
                    name="phone"
                    type="text"
                    id="phone"
                    className="form-control"
                    placeholder="Téléphone "
                    disabled={isSubmitting}
                  />
                  {errors.phone && touched.phone ? (
                    <div className="errors">{errors.phone}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <Field
                    name="subject"
                    type="text"
                    id="subject"
                    className="form-control"
                    placeholder="Saisir l'objet du message"
                    disabled={isSubmitting}
                  />
                  {errors.subject && touched.subject ? (
                    <div className="errors">{errors.subject}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <Field
                    name="message"
                    id="message"
                    placeholder="Votre message"
                    className="form-control"
                    autoComplete="off"
                    as="textarea"
                    disabled={isSubmitting}
                  />
                  {errors.message && touched.message ? (
                    <div className="errors">{errors.message}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="center-wrap text-center">
                  <div className="button">
                    <button type="submit">
                      Envoyer <i className="fa fa-long-arrow-right"></i>{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
